<template>
  <div id="inforIndex_mn">
    <lay-file-select
        :TypeName="TypeName"
        @maskClick="maskClick"
        @isClose="closeAlert"
        :IsShow="confirm.IsShow"
        :fileType="fileType"
        @onClick="selected"
        :option="fileList"></lay-file-select>
    <div class="info_bg" :style="{backgroundImage:'url('+details_pic+')'}"></div>
    <div class="info_content" ref="ingW">
      <div class="left">
        <img :style="{width:'10rem',height:'13rem'}" v-lazy="infosData.img" alt/>
      </div>
      <div class="collect">
       <div  style="margin-right:0.5rem;"  v-if="showPdf"
             @click="showSelectPdf">
         <img src="@/assets/img/pdf.png" alt/>
       </div>
      </div>
      <div class="top">
        <div class="tabs">
          <div id="aqt_event_cont_tab">
            <div
                class="aqt_tab_div"
                ref="Tabs"
                v-for="(item, index) in tabs"
                :key="index"
                @click="clickHandler(item, index)">
              <i :class="'iconfont ' + item.icon"></i>
            </div>
          </div>
        </div>
      </div>
      <component :is="componentId" class="componentss" :data="infosData"></component>

    </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";

export default {
  name: 'inforindex_mnName',
  components: {
    // 基础信息
    BooksInfo: resolve => {
      require(['@/components/books_info.vue'], resolve);
    },
    // 馆藏信息
    BooksInfo1: resolve => {
      require(['@/components/books_info1.vue'], resolve);
    },
    // 目录
    BookIndex: resolve => {
      require(['@/components/books_index.vue'], resolve);
    },
    // 多图
    Books_Intro: resolve => {
      require(['@/components/books_Intro.vue'], resolve);
    },
  },
  data() {
    return {
      watchcount: 0,
      poster: null,
      TypeName: "",
      fileType: 1,
      confirm: {
        IsShow: false,
      },

      fileList: [],
      pdfList: [],
      videoList: [],
      musicList: [],
      showLoading: false,

      pdfSrc: "", // pdf文件地址
      showVideo: false,
      showMusic: false,
      showPdf: false,
      componentId: "BooksInfo",
      keep_type: "1",
      tabs: [
        {
          icon: "iconjianjie",
          icony: "books_infos2",
          iconh: "books_infos1",
          class_id: 0,
          isSs: true,
          mark: '基础信息',
        },
        {
          icon: "iconmulu",
          icony: "books_infos4",
          iconh: "books_infos3",
          class_id: 1,
          isSs: true,
          mark: '目录',
        },
        {
          icon: "iconshoucang",
          icony: "books_infos6",
          iconh: "books_infos5",
          class_id: 2,
          isSs: true,
          mark: '馆藏信息',
        },
        {
          icon: "iconqita2",
          icony: "books_infos8",
          iconh: "books_infos7",
          class_id: 3,
          isSs: true,
          mark: '多图',
        }
      ],
      infosData: {},
      data: {
        //收藏
        book_id: "",
        member_id: null,
        type: "1",
        library_id: null,
      },
      data1: {
        //获取书籍数据
        book_id: "",
        member_id: null,
      },
      data2: {
        //取消收藏
        book_id: "",
        member_id: null,
        type: "1",
        library_id: null,
      },
      data3: {
        //阅读量
        book_id: "",
        type: "1",
        library_id: null,
      },
      type: "",
      reading: "",
      imgW:'',
      qrcodeWH:''
    };
  },
  methods: {
    //计算图片的宽高
    jsWH() {
      let _this = this
      _this.$nextTick(function () {
        var teBox = _this.$refs.ingW
        _this.imgW = teBox.clientWidth * 0.2 + 'px'
        _this.qrcodeWH = teBox.clientWidth * 0.2 / 1.5
      })
    },
    selected(item) {
      console.log(item.type);
      if (item.type == 1) {
        if (item.lx == 1) {
          this.$router.push({
            path: "/zhPdfView",
            query: {
              url: item.url,
            },
          });
          sessionStorage.setItem('pdfOpen', true)
        } else if (item.lx == 2) {
          this.$router.push({
            path: "/h5Iframe",
            query: {
              url: item.url,
            },
          });
        }


      } else if (item.type == 2) {
        //音频
        this.audio.url = item.url;
        this.showMusicPlayer();
      } else if (item.type == 3) {
        //视频
        this.video.url = item.url;
        this.poster = item.videopic;
        this.showVideoPlayer();
      }
    },
    maskClick() {
      this.confirm.IsShow = false;
    },
    closeAlert() {
      this.confirm.IsShow = false;
    },
    //点击pdf
    showSelectPdf() {
      (this.TypeName = "ᠡᠬᠢ ᠰᠤᠷᠪᠤᠯᠵᠢ"),
          (this.fileType = 1),
          (this.fileList = this.pdfList);
      this.confirm.IsShow = true;
    },

    /************************************************************
     *
     * 点击选项卡
     *
     ************************************************************/
    async onTabClick(e) {
      let _this = this;

      switch (e.class_id) {
        case 0:
          console.log("1");
          if (_this.infosData) {
            _this.componentId = 'BooksInfo';
          }
          break;
        case 1:
          console.log("2");
          if (this.infosData) {
            _this.componentId = 'BookIndex';
          }
          break;
        case 2:
          console.log("3");
          if (this.infosData) {
            _this.componentId = 'BooksInfo1';
          }
          break;
        case 3:
          console.log("4");
          if (this.infosData) {
            _this.componentId = 'Books_Intro';
          }
          break;
        case 4:
          console.log("5");
          if (this.infosData) {
            _this.componentId = 'books_comments';
          }
          break;
      }
    },
    //tab栏切换
    clickHandler(item, index) {
      console.log('item',item)
      console.log('index',index)
      let _this = this;
      _this.tabs.forEach((item, index) => {
        this.$refs.Tabs[index].classList.remove("active");
      });
      this.$refs.Tabs[index].classList.add("active");
      _this.onTabClick(item);
    },
    /*****************************************************************
     *
     * 获取详情数据
     *
     *****************************************************************/
    infoData() {
      const _this = this;
      let condition = {
        book_id: _this.$route.query.id,
        library_id: _this.library_id ? _this.library_id : _this.$route.query.number,
        annexboolean: true
      }
      _this.infosData = {}
      _this.pdfList = []
      _this.musicList = []
      _this.videoList = []
      _this.showPdf = false
      _this.showMusic = false
      _this.showVideo = false

      _this.showToastMask(true);
      _this.$http.get(`api/web/v1/h5/find`, {params: condition})
          .then((val) => {
            console.log('书籍详情', val)
            _this.infosData = {}
            _this.pdfList = []
            _this.musicList = []
            _this.videoList = []
            _this.showPdf = false
            _this.showMusic = false
            _this.showVideo = false

            _this.keep_type = val.data.keep_type;
            _this.type = val.data.type;
            val.data.annex.forEach((item) => {
              if (item.type == 1) {
                _this.pdfList.push({
                  type: item.type,
                  url: item.url,
                  name: item.name,
                  size: item.size,
                  videopic: item.videopic,
                  voratime: item.voratime,
                  lx: item.lx
                });
                if (_this.pdfList.length == 0) {
                  _this.showPdf = false;
                } else {
                  _this.showPdf = true;
                }

              }
            });
            _this.infosData = val.data;

            _this.showToastMask(false);
          }).catch((err) => {
        _this.showToastMask(false);
      });
    },
  },
  computed: {
    details_pic() {
      let mm = JSON.parse(localStorage.getItem("theme_color")) ? JSON.parse(localStorage.getItem("theme_color")).details_pic : ''
      return mm
    },
    // 馆藏id
    library_id() {
      let vv = localStorage.getItem("theme_color") ? JSON.parse(localStorage.getItem("theme_color")).library_id : "";
      return vv
    },
    // 是否显示资源
    annexboolean() {
      return this.$store.getters.annexboolean
    },
    // 用户id
    member_id() {
      let mm = JSON.parse(localStorage.getItem("userInfoMemberId")) ? JSON.parse(localStorage.getItem("userInfoMemberId")).member_id : ''
      return mm
    },
  },
  watch: {
    '$store.getters.annexboolean': function (newValue, oldValue) {
      if (newValue != oldValue) {
        this.watchcount++
        if (this.watchcount === 1) {
          this.infoData();
        }
      } else {
        this.watchcount = 0
      }
    },

  },
  beforeRouteLeave(to, from, next) {
    if (to.path !== '/PdfView' || to.path !== '/LiteratureContent') {
      sessionStorage.setItem('pdfOpen', false)
    }
    if(to.path != '/zhPdfView'&&to.path != '/h5Iframe'){
      localStorage.removeItem("youmuBook_intro");
      localStorage.removeItem("youmuBook_index");
    }
    next();
  },
  beforeRouteEnter(to, from, next) {
    if(from.path !='/zhPdfView'&&from.path != '/h5Iframe'){
      localStorage.removeItem("youmuBook_intro");
      localStorage.removeItem("youmuBook_index");
    }
    if (from.path == '/h5Iframe') {
      next(vm => {
        vm.infoData()
      });
    } else {
      next()
    }
  },
  created() {
    this.jsWH()
    this.infoData()
    console.log(77)
  },
  mounted() {
    this.clickHandler(this.tabs[0], 0);
  }
};
</script>

<style lang="less" scoped>
#inforIndex_mn {
  //height: 100%;
  //overflow-x: auto;
  //overflow-y: hidden;
  position: relative;
  //padding-bottom: 40px;
  height: 100%;

  .video {
    // width: 100vw;
    height: 250px;

    video {
      width: 100vw;
      max-height: 250px;
    }
  }

  #aqt_event_cont_tab {
    width: 100%;
    height: 6rem;
    background-color: #ffffff;
    // display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    overflow-x: auto;

    .aqt_tab_div {
      border: 1px solid var(--themeColor);
      width: 8rem;
      flex: none;
      height: 4rem;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: center;
      display: -ms-flexbox;
      justify-content: center;

      // overflow: hidden;
      img {
        width: 100%;
      }

      i {
        font-size: 6rem;
        color: var(--themeColor);
      }
    }

    .active {
      i {
        color: #fff;
      }

      background-color: var(--themeColor);
    }

    .aqt_tab_div:nth-child(1) {
      border-bottom-left-radius: 5px;
      border-top-left-radius: 5px;
    }

    .aqt_tab_div:last-child {
      border-bottom-right-radius: 5px;
      border-top-right-radius: 5px;
    }
  }

  .info_bg {
    //height: 25.5rem;
    height: 19rem;
    // background: url('../../assets/img/book_info_bg.png') 0 0 no-repeat;
    background-size: 100%;
  }

  .info_content {
    background-color: #fff;
    border-radius: 1.5rem 1.5rem 0 0;
    width: 100%;
    position: absolute;
    //top: 16rem;
    top: 10rem;
    height: calc(100% - 10rem);

    .componentss {
      overflow: visible;
      margin-top: 13rem;
      height: calc(100% - 13rem);
    }

    .left {
      position: absolute;
      top: -8rem;
      left: 2rem;

      //img {
      //  width: 10rem;
      //  height: 16rem;
      //}
      .qrcode{
        position: absolute;
        bottom: 0;
        right: 0;
        img{
          width: 100%;
          height: 100%;
        }
      }
    }

    .visit {
      position: absolute;
      right: 18rem;
      top: -4rem;
      display: flex;
      align-items: center;

      img {
        width: 2.6rem;
        height: 2.6rem;
      }

      span {
        margin-left: 5px;
        font-size: 16px;
        color: #fff;
      }

      i {
        font-size: 22px;
        color: #fff;
      }
    }

    .collect {
      position: absolute;
      right: 2rem;
      top: -4rem;

      img {
        width: 2.6rem;
        height: 2.6rem;
      }
    }

    .top {
      width: 100%;
      height: 6rem;
      //display: flex;
      position: absolute;
      padding: 0 10px;
      top: 4rem;

      .tabs {
        margin: 0 auto;
        // width: 23rem;
      }
    }
  }
}
</style>
